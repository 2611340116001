export const URL_DASHBOARD = "/dashboard";
export const URL_USER = "/users";
export const URL_PLANING_AUTHORITY = "/planning-authorities";
export const URL_ADMIN = "/admins";
export const URL_LOGIN = "/login";
export const URL_STAFF_MEMBERS = "/staff-members";
export const URL_SETTINGS = "/settings";
export const URL_SETTINGS_APP = "/settings/app";
export const URL_PAGES = "/pages";
export const URL_INTERESTS = "/interests";
export const URL_Project_Phases = "/project-phases";
export const URL_CITY = "/city";
export const URL_COMPANIES = "/company";
export const URL_COMPANY_MEMBER = "/company-member";
export const URL_PROJECTS = "/projects";
export const URL_TEMPLEATES = "/templates";
export const URL_SUBSCRIPTION_HISTORY = "/subscription-history";
export const URL_CHANGE_PASSWORD = "/change-password";
export const URL_EVENTS = "/events";
export const URL_SUPER_ADMIN = "/super-admin";
